import React, { FunctionComponent, useState, useEffect, ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMutation } from '@tanstack/react-query';

// import { check_subdomain } from '../../lib/server_helper';
import { loginAtom, do_logout, renew_sign_in } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';
import { fetch_one } from "../../lib/v31lib";

import HelpIcon from '@mui/icons-material/Help';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import EhealthBlack from '../../images/ehealth_logo_black_c.svg';
import EhealthRed from '../../images/ehealth_logo_red_c.svg';
import EhealthGreen from '../../images/ehealth_logo_green_c.svg';

import {
  Alert,
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar
} from '@mui/material';

import {
  Typography
} from '../../components/v2/styled';
import { Base } from '../../components/v2/dialogs/Base';
import { PracticeLogo } from '../../components/v2/PracticeLogo';

import HelpersEhealth from '../../actions/helpers/ehealth';
import HelpersNutriMail from '../../actions/helpers/nutri_mail';
// import HelpersPouch from '../../actions/helpers/pouch';
// import HelpersPractice from '../../actions/helpers/practice';
// import HelpersSync from '../../actions/helpers/sync';
import HelpersVersion from '../../actions/helpers/version';

const { DateTime } = require("luxon");

type Props = {
  drawerOpen: boolean;
  setDrawerOpen: (b:boolean) => void;
}

export const SharedHeader: FunctionComponent<Props> = ({drawerOpen, setDrawerOpen}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const matchesmd = useMediaQuery(theme.breakpoints.up('md'));

  const [unreadEmails, setUnreadEmails] = useState<number>(0);
  const [unreadEmailsSnackOpen, setUnreadEmailsSnackOpen] = useState<boolean>(false);
  const [loginMenuOpen, setLoginMenuOpen] = useState<boolean>(false);
  const [loginMenuAnchor, setLoginMenuAnchor] = useState<any>();
  const [mailMenuOpen, setMailMenuOpen] = useState<boolean>(false);
  const [mailMenuAnchor, setMailMenuAnchor] = useState<any>();
  const [tick, setTick] = useState<number>(0);
  const [unreadNutri, setUnreadNutri] = useState<number>(-1);
  const [unreadEhealth, setUnreadEhealth] = useState<number>(-1);

  const [ehealthMailsLoading, setEhealthMailsLoading] = useState<boolean>(false);
  const [ehealthMailsSuccess, setEhealthMailsSuccess] = useState<boolean>(false);
  const [isEhealthMailsError, setIsEhealthMailsError] = useState<boolean>(false);

  const [ehealthInitOpen, setEhealthInitOpen] = useState<boolean>(false);

  let has_ehealth = HelpersEhealth.ehealth_initialised(login);
  let can_check_ehealth = HelpersEhealth.can_check_ehealth(login);
  // if (!!login && (login.practice.id === 4 || login.practice.id === 13 || login.practice.id === 12)) can_check_ehealth = true;




  
  const checkUnread = (nutri_mail:number, ehealth:number) => {
    let old = unreadNutri + unreadEhealth;
    let neww = nutri_mail + ehealth;
    setUnreadNutri(nutri_mail < 0 ? 0 : nutri_mail);
    setUnreadEhealth(ehealth < 0 ? 0 : ehealth);
    
    if (neww > 0 && old >= 0 && old < neww) {
      setUnreadEmailsSnackOpen(true);
    }
  }

  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      daynote?: boolean;
    }) => {
      return fetch_one<{custom_result: {nutri_mail: number; ehealth: number;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      checkUnread(data.custom_result.nutri_mail, data.custom_result.ehealth);
    },
  });








  //mutate check mails
  useEffect(() => {
    if (!!login) {
      mutationCustomAction.mutate({
        formData: {
          object: 'user',
          class_action: 'custom_api_get_unread_emails',
          id: login?.id
        }
      });
    }
  }, [!!login]);
  useEffect(() => {
    let i = 0;
    const intervalId = setInterval(() => {
      i += 1;
      setTick(i);
      // console.log('tickytick', tick, DateTime.now().toFormat("dd/LL/y t"));
    }, HelpersNutriMail.check_mail_interval())
  
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log("renew_sign_in (h)");
      renew_sign_in(() => {});
    }, 12 * 60 * 1000)
  
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (!!login) {
      mutationCustomAction.mutate({
        formData: {
          object: 'user',
          class_action: 'custom_api_get_unread_emails',
          id: login?.id
        }
      });
    }
  }, [tick]);


  let is_nutri_mail = false;
  let is_ehealth = false;
  if (currentPractice) {
    // Modules
    (currentPractice.modules || []).forEach(e => {
      // if (!is_nutri_appointment) is_nutri_appointment = e.id === 1;
      // if (!is_nutri_workshop) is_nutri_workshop = e.id === 2;
      if (!is_nutri_mail) is_nutri_mail = e.id === 13;
      if (!is_ehealth) is_ehealth = e.id === 17;
      // if (!is_questionnaire) is_questionnaire = e.id === 19;
    });
  }

  return <AppBar
    position="absolute"
    sx={{
      zIndex: 1001,
      backgroundColor: 'white'
    }}
  >
    {HelpersVersion.show_version_notice() && <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      padding: 5,
      zIndex: 3000,
      backgroundColor: 'rgba(255, 153, 0, 0.9)',
      color: 'black',
      textAlign: 'center'
    }}>
      <Typography variant="caption">{t("general.messages.version_notice", "Er zijn verbeteringen gebeurd aan NURTiPORTAL.")} <Button sx={{fontSize: '0.7rem', marginLeft: 2}} size="small" variant="outlined" onClick={(e) => {e.preventDefault(); window.location.reload();}}>{t("general.messages.version_notice_cta", "Herlaad nu")}</Button></Typography>
    </Box>}

    <Toolbar
      sx={{
        height: {
          xs: 56,
          md: 75
        }
      }}
      // ref={this.topheader}
    >
      {!matchesmd && (<IconButton sx={{
        marginLeft: -1.5,
        marginRight: 2.5
      }} aria-label="Menu" onClick={() => {
        setDrawerOpen(!drawerOpen);
      }}>
        <MenuIcon />
      </IconButton>)}

      <Box sx={{
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        height: 56,
        marginLeft: {
          xs: 0,
          md: 0 // 69?
        },
        "& > img": {
          margin: 0,
          maxHeight: "75%"
        },
        paddingLeft: {
          xs: 0,
          md: `${40 + 40 + 40 + (!!can_check_ehealth && !!has_ehealth && window.innerWidth >= 600 ? 40 : 0) + 71}px`
        },
      }}>
        {!!login && !!currentPractice && !!currentPractice.custom_theme && !!currentPractice.custom_theme.primary_main && <Typography variant="h1" sx={{
          color: 'black',
          fontSize: {
            xs: '0.8rem',
            md: '1.75rem'
          }
        }}>{currentPractice.name}</Typography>}
        {!(!!login && !!currentPractice && !!currentPractice.custom_theme && !!currentPractice.custom_theme.primary_main) && <PracticeLogo practice={currentPractice} />}
      </Box>

      {!!login && <>
        <IconButton onClick={(e) => {e.preventDefault(); window.open("https://www.nutrilink.eu/faq/", "_BLANK");}}>
          <HelpIcon />
        </IconButton>

        <IconButton onClick={() => {navigate("/news");}}>
          <MenuBookIcon />
        </IconButton>

        {!!can_check_ehealth && !!has_ehealth && window.innerWidth >= 600 && <IconButton
          sx={{position: 'relative'}}
          onClick={() => {
            if (!!login.ehealth?.connect && !!login.ehealth?.status && login.ehealth.status.key !== "connected") {
              window.open(login.ehealth.connect, "_BLANK");
            }
            if (!!login.ehealth?.connect && !!login.ehealth?.status && login.ehealth.status.key === "connected") {
              // this.check_mails();
            }
          }}
          title={`Last stamp: ${!!login.ehealth?.status ? DateTime.fromISO(login.ehealth.status.stamp).toFormat("DD/LL/y t") : 'none'}${!!login.ehealth?.connect && !!login.ehealth?.status && login.ehealth.status.key !== "connected" ? ' - Click to (re)connect' : ''}`}
        >
          {!!ehealthMailsLoading && <CircularProgress style={{position: 'absolute', top: 2, left: 2}} />}

          {!has_ehealth || (has_ehealth && !!login.ehealth?.status && login.ehealth.status.key !== "connected" && login.ehealth.status.key !== "broken") && <img src={EhealthBlack} alt="Ehealth Connection" style={{width: 22, maxHeight: 20}} />}
          {has_ehealth && !!login.ehealth?.status && login.ehealth.status.key === "broken" && <img src={EhealthRed} alt="Ehealth Connection" style={{width: 22, maxHeight: 20}} />}
          {has_ehealth && !!login.ehealth?.status && login.ehealth.status.key === "connected" && <img src={EhealthGreen} alt="Ehealth Connection" style={{width: 22, maxHeight: 20}} />}
        </IconButton>}
        {!!can_check_ehealth && !has_ehealth && window.innerWidth >= 600 && <IconButton
          sx={{position: 'relative'}}
          onClick={() => {
            setEhealthInitOpen(true);
          }}
        >
          <img src={EhealthBlack} alt="Ehealth Connection" style={{width: 22, maxHeight: 20}} />
        </IconButton>}

        <IconButton onClick={(event) => {
          if (!!can_check_ehealth) {
            setMailMenuAnchor(event.currentTarget);
            setMailMenuOpen(true);
          } else {
            navigate("/inbox");
          }
        }}>
          <Badge badgeContent={unreadNutri + unreadEhealth} color="primary">
            <MailIcon />
          </Badge>
        </IconButton>
        <Menu
          id="menu-mail-appbar"
          anchorEl={mailMenuAnchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={mailMenuOpen}
          onClose={() => {
            setMailMenuOpen(false);
            setMailMenuAnchor(undefined);
          }}
        >
          <MenuItem onClick={() => {
            navigate("/inbox");
            setMailMenuOpen(false);
            setMailMenuAnchor(undefined);
          }}>{t("shared.actions.inbox", "NutriMail")}</MenuItem>
          <MenuItem onClick={() => {
            navigate("/ehealth_inbox");
            setMailMenuOpen(false);
            setMailMenuAnchor(undefined);
          }}>{t("shared.actions.ehealth_inbox", "EhealthMail")}</MenuItem>
        </Menu>

        <IconButton onClick={(event) => {
          setLoginMenuAnchor(event.currentTarget);
          setLoginMenuOpen(true);
        }}>
          <Box sx={{
            backgroundColor: theme.palette.primary.main,
            padding: 0.5,
            borderRadius: (47 + 3) / 2,
            borderWidth: 1,
            width: 47,
            height: 47,
            boxSizing: "content-box",
            margin: 0
          }}>
            <Avatar sx={{
              width: 47,
              height: 47
            }} src={login && login.get_item_thumb ? login.get_item_thumb : "/images/default-avatar.png"}/>
          </Box>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={loginMenuAnchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={loginMenuOpen}
          onClose={() => {
            setLoginMenuOpen(false);
            setLoginMenuAnchor(undefined);
          }}
        >
          <MenuItem onClick={() => {
            navigate("/");
            setLoginMenuOpen(false);
            setLoginMenuAnchor(undefined);
          }}>{t("shared.actions.dashboard")}</MenuItem>
          <MenuItem onClick={() => {
            if (login?.type === "User::User") navigate("/settings", {state: {tab: 0}});
            if (login?.type === "User::Coach") navigate("/settings", {state: {tab: 0}});
            if (login?.type === "User::Admin") navigate("/settings", {state: {tab: 0}});
            setLoginMenuOpen(false);
            setLoginMenuAnchor(undefined);
          }}>{t("shared.actions.profile")}</MenuItem>
          <MenuItem onClick={() => {
            do_logout(() => {
              // @ts-ignore
              window.location = "/login";
            });
            navigate("/");
            setLoginMenuOpen(false);
            setLoginMenuAnchor(undefined);
          }}>{t("shared.actions.sign_out")}</MenuItem>
        </Menu>

        <Snackbar
          open={unreadEmailsSnackOpen}
          autoHideDuration={5 * 1000}
          onClose={(e, r) => {
            if (r === 'clickaway') {
              return;
            }
            setUnreadEmailsSnackOpen(false);
          }}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="info"
            onClick={() => {
              setUnreadEmailsSnackOpen(false);
              navigate('/inbox');
            }}
            sx={{
              cursor: 'pointer',
              backgroundColor: '#E69E54'
            }}>{t("shared.nutrimail.new_mail_snack", "Er is een nieuwe NutriMail!")}</Alert>
        </Snackbar>
      </>}
      
      {!login && <>
        <Button color="primary" variant="outlined" onClick={() => {
          navigate('/login/');
        }}>
          {t("shared.buttons.sign_in")}
        </Button>
      </>}
    </Toolbar>

    <Base
      name="alert"
      hidden={!ehealthInitOpen}
      title={t("general.ehealth.not_inited_title")}
      content={<Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>{t("general.ehealth.not_inited_content")}</Typography>
        </Grid>
      </Grid>}
      actions={<DialogActions>
        <Button autoFocus onClick={() => {setEhealthInitOpen(false);}}>{t("general.confirm.actions.cancel")}</Button>
        <Button onClick={(e) => {
          navigate("/settings", {state: {tab: 4}});
          setEhealthInitOpen(false);
        }}>{t("general.ehealth.not_inited_action_settings")}</Button>
      </DialogActions>}
      open={ehealthInitOpen}
      setOpen={setEhealthInitOpen}
    />
  </AppBar>;
}

