import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Menu,
  MenuItem
} from '@mui/material';

import HelpersLanguage from '../../actions/helpers/language';

type Props = {
  sx?: any;
  black?: boolean;
};

export const LanguagePicker: FunctionComponent<Props> = ({sx, black}) => {
  const {t, i18n} = useTranslation(['translations']);

  const [langMenuOpen, setLangMenuOpen] = useState<boolean>(false);
  const [langMenuAnchor, setLangMenuAnchor] = useState<null | HTMLElement>(null);

  return (<Box sx={{
    ...sx
  }}>
    <Button onClick={(event) => {
      setLangMenuOpen(true);
      setLangMenuAnchor(event.currentTarget);
    }} sx={{
      ...(!!black ? {
        color: 'black'
      } : {})
    }}>{i18n.resolvedLanguage}</Button>
    <Menu
      id="lang-appbar"
      anchorEl={langMenuAnchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={langMenuOpen}
      onClose={() => {
        setLangMenuOpen(false);
      }}
    >
      {HelpersLanguage.available_locales().map((locale, i) => (<MenuItem key={i} onClick={() => {
        i18n.changeLanguage(locale)
        setLangMenuOpen(false);
        setLangMenuAnchor(null);
      }}>{locale.toUpperCase()}</MenuItem>))}
    </Menu>
  </Box>);
}