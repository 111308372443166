import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import AssignmentIcon from '@mui/icons-material/Assignment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import StarBorderPurple500 from '@mui/icons-material/StarBorderPurple500';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  Avatar,
  Box,
  Button as Btn,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  // IconButton,
  MenuItem,
  Paper,
  Tooltip,
} from '@mui/material';

import {
  Button,
  Dt,
  IconButton,
  Page,
  PremiumOverlayIcon,
  Select,
  Typography
} from '../../../components/v2/styled';
import { Base } from '../../../components/v2/dialogs/Base';
import { CoachShowQuestionnaire } from '../../../components/v2/dialogs/CoachShowQuestionnaire';
import { Premium } from '../../../components/v2/dialogs/Premium';
import { SnackCopied } from '../../../components/v2/snacks/Copied';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Questionnaire } from '../../../models/Questionnaire';
import { QuestionnaireResult } from '../../../models/QuestionnaireResult';
import { User } from '../../../models/User';

import HelpersEhealth from '../../../actions/helpers/ehealth';
import HelpersLanguage from '../../../actions/helpers/language';
import HelpersPractice from '../../../actions/helpers/practice';
import { Confirm } from '../../../components/v2/dialogs/Confirm';

const { DateTime } = require("luxon");

type Props = {}

export const CoachUserDetail: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  const { state } = useLocation();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [copyOpen, setCopyOpen] = useState<boolean>(false);
  const [premiumEhealthOpen, setPremiumEhealthOpen] = useState<boolean>(false);
  const [premiumNutriMailOpen, setPremiumNutriMailOpen] = useState<boolean>(false);
  const [premiumNutriAdviceOpen, setPremiumNutriAdviceOpen] = useState<boolean>(false);
  const [premiumDiaryEatOpen, setPremiumDiaryEatOpen] = useState<boolean>(false);
  const [premiumDiaryMoveOpen, setPremiumDiaryMoveOpen] = useState<boolean>(false);
  const [premiumNutriAppointmentOpen, setPremiumNutriAppointmentOpen] = useState<boolean>(false);
  const [premiumMeasuresWeightOpen, setPremiumMeasuresWeightOpen] = useState<boolean>(false);
  const [passResetLink, setPassResetLink] = useState<string>();
  const [passResetEmail, setPassResetEmail] = useState<string>();
  const [consentAsked, setConsentAsked] = useState<string>();
  const [qResultOpen, setQResultOpen] = useState<boolean>(!!state?.open_q);
  const [activeQResult, setActiveQResult] = useState<number|undefined>(!!state?.open_q ? state.open_q : undefined);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  // const [mainObject, setMainObject] = useState<User>({
  //   auth_token: '',
  //   email: '',
  //   coach_skills: []
  // });
  const [groupsEatEditing, setGroupsEatEditing] = useState<boolean>(false);
  const [groupsMoveEditing, setGroupsMoveEditing] = useState<boolean>(false);
  const [newEatGroup, setNewEatGroup] = useState<string>();
  const [newMoveGroup, setNewMoveGroup] = useState<string>();
  const [invitation, setInvitation] = useState<boolean>(false);
  const [activeQ, setActiveQ] = useState<Questionnaire>();

  const [ehealthInitOpen, setEhealthInitOpen] = useState<boolean>(false);

  let i_solemnly_swear_that_i_am_up_to_no_good = true;

  let is_nutri_appointment = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_appointment'});
  let is_nutri_measures_weight = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_measures_weight'});
  let is_nutri_diary_eat = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_diary_eat'});
  let is_nutri_diary_move = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_diary_move'});
  let is_nutri_mail = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_mail'});
  let is_nutri_advice = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_advice'});
  let is_ehealth = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'ehealth'});

  let has_ehealth = HelpersEhealth.ehealth_initialised(login);
  let can_check_ehealth = HelpersEhealth.can_check_ehealth(login);

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["user", 'full', (login?.practice_id || currentPractice?.id), objectId],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          id: objectId,
          fields: [
            'id', 'first_name', 'second_name', 'last_name', 'email', 'consent_status', 'call_name', 'birth_date', 'language_id', 'gender',
            'phone_fixed', 'phone_mobile', 'street', 'number', 'zip', 'city', 'country_id', 'spouse_name', 'national_number', 'get_medium',
            'full_fm_links', 'settings', 'country|id/name', 'module_status', 'full_eat_groups', 'full_move_groups', 'merge_pending', 'mfa_status',
            'questionnaire_results'
          ],
          sub_fields: {
            'questionnaire_results': ['id', 'questionnaire_id', 'extra_data', 'completed_at', 'invited_at', 'mail_sent_at', 'is_locked', 'comment']
          },
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
      };
    },
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });
  const {
    data: intakeProgress,
    isLoading: intakeProgressLoading,
    isError: intakeProgressError,
    isSuccess: intakeProgressSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["intake_progress", objectId, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_one<{custom_result: number}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_get_intake_progress',
          id: objectId,
        },
        login
      ),
    enabled: !!login,
  });
  const {
    data: questionnaires,
    // isLoading: objectsLoading,
    // isError: objectsError,
    // isSuccess: objectsSuccess,
    // refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "questionnaires_short", (login?.practice_id || currentPractice?.id)],
    queryFn: () =>
      fetch_all<Questionnaire>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire',
          fields: ['id', 'name'],
          order: "id DESC",
          per_page: 20,
          page: 0,
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id)
            }
          },
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    enabled: !!(login?.practice_id || currentPractice?.id),
  });
  const {
    data: questionnaireResult,
    // isLoading: objectLoading,
    // isError: objectError,
    // isSuccess: objectSuccess,
    refetch: questionnaireResultRefetch,
  } = useQuery({
    queryKey: ["questionnaire_result", (login?.practice_id || currentPractice?.id), activeQResult],
    queryFn: () =>
      fetch_one<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire_result',
          id: activeQResult,
          fields: [
            'id', 'name', 'extra_data', 'questionnaire', 'practice', 'comment', 'is_locked'
          ],
          sub_fields: {
            'practice': ['id', 'name'],
            'questionnaire': ['id', 'name', 'extra_data']
          },
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    // select: (d) => {
    //   return {
    //     ...d,
    //     birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
    //   };
    // },
    enabled: !!(login?.practice_id || currentPractice?.id) && !!activeQResult,
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      pass?: boolean;
      consent?: boolean;
      group?: boolean;
      unlink?: boolean;
      mfa?: boolean;
    }) => {
      return fetch_one<{custom_result: {success?: boolean; url?: string; email?: string;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (variables.pass) {
        setPassResetLink(data.custom_result.url);
        setPassResetEmail(data.custom_result.email);
      }
      if (variables.consent) setConsentAsked(data.custom_result.success ? 'asked' : 'error');
      if (variables.group) {
        setGroupsEatEditing(false);
        setGroupsMoveEditing(false);
        setNewEatGroup(undefined);
        setNewMoveGroup(undefined);
        objectRefetch();
      }
      if (variables.unlink) {
        navigate('/users');
      }
      if (variables.mfa) {
        objectRefetch();
      }
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectRefetch();
    },
  });






  let consentColour = (bool:boolean, pending: boolean) => {
    return <>
      {bool && <Typography sx={{
        color: theme.palette.primary.main
      }}>{t("coach.consents.option_yes")}</Typography>}
      {!bool && pending && <Typography sx={{
        color: theme.palette.warning.main
      }}>{t("coach.consents.pending")}</Typography>}
      {!bool && !pending && <Typography sx={{
        color: theme.palette.secondary.main
      }}>{t("coach.consents.option_no")}</Typography>}
    </>
  }
  let notificationColour = (bool:boolean) => {
    return <>
      {bool && <Typography sx={{
        color: theme.palette.primary.main
      }}>{t("coach.notifications.option_yes")}</Typography>}
      {!bool && <Typography sx={{
        color: theme.palette.secondary.main
      }}>{t("coach.notifications.option_no")}</Typography>}
    </>
  }

  let renderDetailContact = (ob: User, xsClass: string, xlClass: string, marginTop: number) => {
    return <Paper sx={{padding: 2, marginTop: marginTop, display: {
      xs: xsClass,
      xl: xlClass
    }, position: 'relative'}}>
      {!!ob.consent_status?.is_basic && <IconButton sx={{position: 'absolute', top: 1, right: 1}} title="Edit" color="primary" onClick={() => {
        navigate(`/user/${ob.id}`, {state: {detail: true}});
      }}>
        <EditIcon />
      </IconButton>}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("coach.submenu.detail.contact_information")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("coach.models.clients.email")}</Typography>
          <Typography>{ob.email}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("coach.models.clients.phone_fixed")}</Typography>
          <Typography>{ob.phone_fixed}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("coach.models.clients.phone_mobile")}</Typography>
          <Typography>{ob.phone_mobile}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("coach.models.clients.address")}</Typography>
          <Typography>{ob.street} {ob.number}{!!ob.street && ", "}{ob.zip} {ob.city}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("coach.models.clients.country")}</Typography>
          <Typography>{ob.country_name}</Typography>
        </Grid>

      </Grid>
    </Paper>;
  }
  let renderMeasuresAndAppointments = (ob: User, xsClass: string, xlClass: string) => {
    return <><Paper sx={{padding: 2, marginTop: 1, display: {
      xs: xsClass,
      xl: xlClass
    }}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("coach.submenu.detail.appointments")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Button
            label={t("coach.general.actions.to_appointments", "Naar de afspraken")}
            id='goto_calendar'
            contained
            premiumLocked={!is_nutri_appointment}
            onClick={(v) => {
              if (!!is_nutri_appointment) {
                navigate(`/user/${ob.id}/appointments`, {state: {user_id: ob.id}});
              } else {
                setPremiumNutriAppointmentOpen(true);
              }
            }}
            sx={{marginTop: 2}}
          />
        </Grid>
      </Grid>
    </Paper>

    {!login?.is_secretary && <Paper sx={{padding: 2, marginTop: 1, display: {
      xs: xsClass,
      xl: xlClass
    }}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("coach.submenu.detail.measures")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          {!ob.module_status?.measures_day && <Typography sx={{fontStyle: 'italic'}}>{t("coach.diary.no_data_short")}</Typography>}
          {!!ob.module_status?.measures_day && <Typography sx={{fontSize: '0.8rem'}}>{t("shared.terms.last_entry", "Laatste ingave")}: <Dt o={ob.module_status?.measures_day} f="DDD" /></Typography>}
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Button
            label={t("coach.general.actions.to_measures", "Naar de metingen")}
            id='goto_measures'
            contained
            premiumLocked={!is_nutri_measures_weight}
            onClick={(v) => {
              if (!!is_nutri_measures_weight) {
                navigate(`/user/${ob.id}/measures`);
              } else {
                setPremiumMeasuresWeightOpen(true);
              }
            }}
            sx={{marginTop: 2}}
          />
        </Grid>
      </Grid>
    </Paper>}</>}
  let renderAccountMngmt = (ob: User, xsClass: string, xlClass: string) => {
    return <Paper sx={{padding: 2, marginTop: 1, display: {
      xs: xsClass,
      xl: xlClass
    }}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("coach.submenu.detail.account_management")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{marginTop: 1}}>
          <Typography variant="subtitle2">{t("coach.submenu.detail.password_reset")}</Typography>
        </Grid>
        {!passResetLink && <Grid item xs={12}>
          <Button
            id="passreset"
            contained
            onClick={() => {
              mutationCustomAction.mutate({
                formData: {
                  object: 'user',
                  class_action: 'custom_api_inquire_new_password',
                  id: ob.id
                },
                pass: true
              });
            }}
            label={t("coach.general.buttons.request_pass_reset_link")}
            sx={{marginTop: 1}}
          />
        </Grid>}
        {!!passResetLink && <Grid item xs={12}>
          <Typography sx={{fontSize: '0.9rem'}}>{t("coach.submenu.detail.password_reset_explanation")}</Typography>
          <Typography sx={{fontSize: '0.8rem', paddingLeft: 2}}>{passResetEmail} <ContentCopyIcon
            sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1, color: theme.palette.primary.main}}
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(passResetEmail || '');
              setCopyOpen(true);
            }}
          /></Typography>
          <Typography sx={{fontSize: '0.9rem', marginTop: 1}}>{t("coach.submenu.detail.password_reset_explanation_link")}</Typography>
          <Typography sx={{fontSize: '0.8rem', wordBreak: 'break-all', paddingLeft: 2}}>{passResetLink} <ContentCopyIcon
            sx={{cursor: 'pointer', scale: '0.7', marginRight: 1, marginBottom: -1, color: theme.palette.primary.main}}
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(passResetLink || '');
              setCopyOpen(true);
            }}
          /></Typography>
        </Grid>}
        {ob.mfa_status === 1 && <Grid item xs={12} sx={{marginTop: 1}}>
          <Typography variant="subtitle2">{t("coach.personal_settings.security.two_factor.title")}</Typography>
          <Typography variant="caption">{t("coach.personal_settings.security.two_factor.user_detail_line")}</Typography>
        </Grid>}
        {ob.mfa_status === 1 && <Grid item xs={12} sx={{marginTop: 1}}>
          <Button
            id="2fa_disable"
            contained
            secondary
            destroyBtn
            onClick={() => {
              mutationCustomAction.mutate({
                formData: {
                  object: 'user',
                  class_action: 'custom_api_mfa_actions',
                  id: ob?.id,
                  mfa_action: 'disable'
                },
                mfa: true
              });
            }}
            label={t("coach.general.buttons.disable_mfa")}
            sx={{marginTop: 1}}
          />
        </Grid>}
      </Grid>
    </Paper>;
  }
  
  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  // useEffect(() => {
  //   if (!!objectSuccess) setMainObject(object);
  // }, [object]);

  let group_eat = Object.keys((object?.full_eat_groups || {})).filter(ff => (object?.full_eat_groups || {})[ff].practices.filter(fff => fff[0] === currentPractice?.id).length > 0)[0];
  let eat_group_name = ((object?.full_eat_groups || {})[group_eat] || {}).profile?.name;
  let group_move = Object.keys((object?.full_move_groups || {})).filter(ff => (object?.full_move_groups || {})[ff].practices.filter(fff => fff[0] === currentPractice?.id).length > 0)[0];
  let move_group_name = ((object?.full_move_groups || {})[group_move] || {}).profile?.name;

  return <Page
    title={(object || {}).first_name}
    titleCrumbs={[
      {
        title: t("coach.menu.users", "Cliënten"),
        path: "/users"
      }
    ]}
  >
    {!!objectLoading && <Grid container spacing={1}>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <CircularProgress />
      </Grid>
    </Grid>}
    {!!objectSuccess && <Grid container spacing={1}>



      <Grid item xs={12} md={4} xl={3}>

        <Paper sx={{padding: 2}}>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Avatar sx={{
                height: 120,
                width: 120,
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block'
              }} src={object.get_medium}></Avatar>
              <Typography variant="h5" sx={{marginTop: 1}}>{object.first_name} {object.last_name}</Typography>

              <Box>
                {Object.keys(object.full_fm_links || {}).indexOf(currentPractice?.id?.toString() || '') > -1 && <Tooltip title={t("coach.general.actions.open_in_fm", "Openen in FileMaker")}>
                  <IconButton onClick={(event) => {
                    event.preventDefault();
                    window.open((object.full_fm_links || {})[(currentPractice?.id?.toString() || '')], "_BLANK")
                  }}>
                    <Avatar src="https://cdn.kangacoders.com/direct/nutrilink_eu/images/vinkje_blauw_groen.png" />
                  </IconButton>
                </Tooltip>}

                <Tooltip title={t("coach.general.actions.open_logs", "Open de logs van deze client")}>
                  <IconButton onClick={(event) => {
                    event.preventDefault();
                    navigate(`/logs`, {state: {user_id: object.id, first_name: object.first_name, last_name: object.last_name}});
                  }}>
                    <AssignmentIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("coach.general.actions.open_mails", "Open de NutriMails met deze client")}>
                  <IconButton onClick={(event) => {
                    event.preventDefault();
                    if (is_nutri_mail) {
                      navigate(`/inbox`, {state: {user_id: {id: object.id, name: `${object.first_name} ${object.last_name}`}}});
                    } else {
                      setPremiumNutriMailOpen(true);
                    }
                  }}>
                    <PremiumOverlayIcon isPremium={!!is_nutri_mail} Icon={MailIcon} />
                  </IconButton>
                </Tooltip>
                {can_check_ehealth && <Tooltip title={t("coach.general.actions.open_ehealth_mails", "Open de EhealthMails met deze client")}>
                  <IconButton onClick={(event) => {
                    event.preventDefault();
                    if (is_ehealth) {
                      if (has_ehealth) {
                        navigate(`/ehealth_inbox`, {state: {user_id: {id: object.id, name: `${object.first_name} ${object.last_name}`}, linked_client: {value: object.id, label: `${object.first_name} ${object.last_name}`, rrn: object.national_number}}});
                      } else {
                        setEhealthInitOpen(true);
                      }
                    } else {
                      setPremiumEhealthOpen(true);
                    }
                  }}>
                    <PremiumOverlayIcon isPremium={!!is_ehealth} Icon={ContactMailIcon} />
                  </IconButton>
                </Tooltip>}
              </Box>
            </Grid>

          </Grid>
        </Paper>

        <Paper sx={{padding: 2, marginTop: 1}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.detail.consent_information")}</Typography>
              <Divider />
            </Grid>
            {!!object.consent_status?.is_blocked && <Grid direction="column" item xs={12}>
              <Typography variant="subtitle2" sx={{color: theme.palette.secondary.main, fontWeight: 'bold', fontSize: '1.2rem'}}>{t("coach.consents.is_blocked")}</Typography>
            </Grid>}
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.consents.enabled")}</Typography>
              {consentColour(!!object.consent_status?.enabled, !!object.consent_status?.pending)}
            </Grid>
            {/* <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.consents.is_basic")}</Typography>
              {consentColour(!!object.consent_status?.is_basic, !!object.consent_status?.pending)}
            </Grid> */}
            {!login?.is_secretary && <Grid direction="column" item xs={12}>
              <Typography variant="subtitle2">{t("coach.consents.is_intake")}</Typography>
              {consentColour(!!object.consent_status?.is_intake, !!object.consent_status?.pending)}
            </Grid>}
            {!login?.is_secretary && <Grid direction="column" item xs={12}>
              <Typography variant="subtitle2">{t("coach.consents.is_measure_weight")}</Typography>
              {consentColour(!!object.consent_status?.is_measure_weight, !!object.consent_status?.pending)}
            </Grid>}
            {!login?.is_secretary && <Grid direction="column" item xs={12}>
              <Typography variant="subtitle2">{t("coach.consents.is_diary_eat")}</Typography>
              {consentColour(!!object.consent_status?.is_diary_eat, !!object.consent_status?.pending)}
            </Grid>}
            {!login?.is_secretary && <Grid direction="column" item xs={12}>
              <Typography variant="subtitle2">{t("coach.consents.is_diary_move")}</Typography>
              {consentColour(!!object.consent_status?.is_diary_move, !!object.consent_status?.pending)}
            </Grid>}

          </Grid>
        </Paper>

        {!!object.consent_status?.enabled && renderAccountMngmt(object, 'block', 'none')}

        <Paper sx={{padding: 2, marginTop: 1}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.detail.notification_information")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("client.settings.notifications.nutriappointment.general")}</Typography>
              {notificationColour(!!object.settings?.notifications?.nutri_appointment)}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("client.settings.notifications.nutriworkshop.general")}</Typography>
              {notificationColour(!!object.settings?.notifications?.nutri_workshop)}
            </Grid>

          </Grid>
        </Paper>

      </Grid>




      <Grid item xs={12} md={4} xl={3}>

        <Paper sx={{padding: 2, position: 'relative'}}>
          {!!object.consent_status?.is_basic && <IconButton sx={{position: 'absolute', top: 1, right: 1}} title="Edit" color="primary" onClick={() => {
            navigate(`/user/${object.id}`, {state: {detail: true}});
          }}>
            <EditIcon />
          </IconButton>}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.detail.basic_information")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.models.clients.first_name")}</Typography>
              <Typography>{object.first_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.models.clients.second_name")}</Typography>
              <Typography>{object.second_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.models.clients.last_name")}</Typography>
              <Typography>{object.last_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.models.clients.call_name")}</Typography>
              <Typography>{object.call_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.models.clients.language")}</Typography>
              <Typography>{HelpersLanguage.mapping_to_iso(object.language_id).toUpperCase()}</Typography>
            </Grid>

          </Grid>
        </Paper>

        {renderDetailContact(object, 'block', 'none', 1)}

        <Paper sx={{padding: 2, marginTop: 1, position: 'relative'}}>
          {!!object.consent_status?.is_basic && <IconButton sx={{position: 'absolute', top: 1, right: 1}} title="Edit" color="primary" onClick={() => {
            navigate(`/user/${object.id}`, {state: {detail: true}});
          }}>
            <EditIcon />
          </IconButton>}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.detail.other")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.models.clients.gender")}</Typography>
              {object.gender === 0 && <Typography>{t("general.genders.female")}</Typography>}
              {object.gender === 1 && <Typography>{t("general.genders.male")}</Typography>}
              {object.gender === 2 && <Typography>{t("general.genders.other")}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.models.clients.spouse_name")}</Typography>
              <Typography>{object.spouse_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.models.clients.birth_date")}</Typography>
              <Dt o={object.birth_date} f="dd/LL/y" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("coach.models.clients.national_number")}</Typography>
              <Typography>{object.national_number}</Typography>
            </Grid>

          </Grid>
        </Paper>

      </Grid>




      <Grid item xs={12} md={4} xl={3} sx={{
        display: {
          xs: 'none',
          xl: 'block'
        }
      }}>

        {renderDetailContact(object, 'none', 'block', 0)}
        {renderAccountMngmt(object, 'none', 'block')}
        {renderMeasuresAndAppointments(object, 'none', 'block')}
      </Grid>




      <Grid item xs={12} md={4} xl={3}>

        {!login?.is_secretary && <Paper sx={{padding: 2}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.detail.intake_profile")}</Typography>
              <Divider />
            </Grid>
            {!object.consent_status?.is_intake && <Grid item xs={12}>
              <Typography sx={{color: theme.palette.warning.main, fontSize: '0.7rem', fontStyle: 'italic'}}>{t("coach.consents.no_access")}</Typography>
            </Grid>}
            {!object.consent_status?.is_intake && !consentAsked && <Grid item xs={12}>
              <Button
                label={t("coach.general.buttons.ask_consent")}
                id='ask_consent2'
                contained
                onClick={(v) => {
                  mutationCustomAction.mutate({
                    formData: {
                      object: 'user',
                      class_action: 'custom_api_ask_consent',
                      email: object?.email,
                      practice_id: (login?.practice_id || currentPractice?.id)
                    }
                  });
                }}
                sx={{marginTop: 2}}
              />
              <Button
                label={t("coach.general.buttons.see_limited_version")}
                id='limited_version'
                size="small"
                text
                onClick={(v) => {
                  navigate(`/user/${object.id}/file`, {state: {tab: 0}});
                }}
                sx={{marginTop: 2}}
              />
            </Grid>}
            {!object.consent_status?.is_intake && !!consentAsked && <Grid item xs={12}>
              <Typography>{t("coach.paragraphs.existing_user_consent_asked")}</Typography>
            </Grid>}
            {!!object.consent_status?.is_intake && <Grid item xs={12} sx={{textAlign: 'center'}}>
              {intakeProgressSuccess && <Typography>{t("coach.user_detail.intake_progress", "Completion:")} {intakeProgress.custom_result}%</Typography>}
              <Button
                label={t("coach.general.actions.intake")}
                id='goto_intake'
                contained
                onClick={(v) => {
                  navigate(`/user/${object.id}/file`, {state: {tab: 0}});
                }}
                sx={{marginTop: 2}}
              />
            </Grid>}
            {!!object.consent_status?.is_intake && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Button
                label={t("coach.general.actions.treatment_plan")}
                id='goto_treatment_plan'
                contained
                premiumLocked={!is_nutri_advice}
                onClick={(v) => {
                  if (is_nutri_advice) {
                    navigate(`/user/${object.id}/file`, {state: {tab: 1}});
                  } else {
                    setPremiumNutriAdviceOpen(true);
                  }
                }}
                sx={{marginTop: 2}}
              />
            </Grid>}
            {!!object.consent_status?.is_intake && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Button
                label={t("coach.general.actions.advices")}
                id='goto_advices'
                contained
                premiumLocked={!is_nutri_advice}
                onClick={(v) => {
                  if (is_nutri_advice) {
                    navigate(`/user/${object.id}/file`, {state: {tab: 2}});
                  } else {
                    setPremiumNutriAdviceOpen(true);
                  }
                }}
                sx={{marginTop: 2}}
              />
            </Grid>}

          </Grid>
        </Paper>}

        {!login?.is_secretary && <Paper sx={{padding: 2, marginTop: 1}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.detail.diary")}</Typography>
              <Divider />
            </Grid>

            <Grid item xs={12} sx={{
              position: 'relative'
            }}>
              <Box sx={{
                zIndex: 1200,
                position: 'absolute',
                top: 8,
                left: 8,
                width: '100%'
              }}>
                <Typography sx={{fontWeight: 'bold'}}>{t("eat_diary.groups.name")}</Typography>
                <Typography sx={{display: 'inline'}}>{t(!!eat_group_name ? eat_group_name : "eat_diary.groups.none")}</Typography>
                <Button
                  id="adjust_eat"
                  sx={{fontSize: '0.7rem', marginLeft: 1, float: 'right', marginTop: -1}}
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setGroupsEatEditing(true);
                  }}
                  label={t("eat_diary.groups.adjust_cta")}
                />
              </Box>
              {!is_nutri_diary_eat && <Box sx={{
                position: 'absolute',
                top: 8,
                left: 8,
                width: '100%',
                zIndex: 1201,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                height: '56px',
                textAlign: 'center',
                cursor: 'pointer'
              }} onClick={() => {
                setPremiumDiaryEatOpen(true);
              }}>
                <StarBorderPurple500 sx={{marginTop: 1, color: theme.palette.primary.main}} />
              </Box>}

              <Divider sx={{marginTop: 7}} />
            </Grid>
            <Grid item xs={12} sx={{
              position: 'relative'
            }}>
              <Box sx={{
                zIndex: 1200,
                position: 'absolute',
                top: 8,
                left: 8,
                width: '100%'
              }}>
                <Typography sx={{fontWeight: 'bold'}}>{t("move_diary.groups.name")}</Typography>
                <Typography sx={{display: 'inline'}}>{t(!!move_group_name ? move_group_name : "move_diary.groups.none")}</Typography>
                <Button
                  id="adjust_eat"
                  sx={{fontSize: '0.7rem', marginLeft: 1, float: 'right', marginTop: -1}}
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setGroupsMoveEditing(true);
                  }}
                  label={t("eat_diary.groups.adjust_cta")}
                />
              </Box>
              {!is_nutri_diary_move && <Box sx={{
                position: 'absolute',
                top: 8,
                left: 8,
                width: '100%',
                zIndex: 1201,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                height: '56px',
                textAlign: 'center',
                cursor: 'pointer'
              }} onClick={() => {
                setPremiumDiaryMoveOpen(true);
              }}>
                <StarBorderPurple500 sx={{marginTop: 1, color: theme.palette.primary.main}} />
              </Box>}

              <Divider sx={{marginTop: 7}} />
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              {!object.module_status?.diary_day && <Typography sx={{fontStyle: 'italic'}}>{t("coach.diary.no_data_short")}</Typography>}
              {!!object.module_status?.diary_day && <Typography sx={{fontSize: '0.8rem'}}>{t("shared.terms.last_entry", "Laatste ingave")}: <Dt o={object.module_status?.diary_day} f="DDD" /></Typography>}
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Button
                label={t("coach.general.actions.to_diary", "Naar het dagboek")}
                id='goto_diary'
                contained
                premiumLocked={!(is_nutri_diary_eat || is_nutri_diary_move)}
                onClick={(v) => {
                  if (is_nutri_diary_eat || is_nutri_diary_move) {
                    navigate(`/user/${object.id}/diary`);
                  } else {
                    setPremiumDiaryEatOpen(true);
                  }
                }}
                sx={{marginTop: 2}}
              />
            </Grid>
          </Grid>
        </Paper>}

        {renderMeasuresAndAppointments(object, 'block', 'none')}

        {!login?.is_secretary && <Paper sx={{padding: 2, marginTop: 1}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.detail.questionnaires")}</Typography>
              <Divider />
            </Grid>
            {(questionnaires || []).map(questionnaire => <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold'}}>{questionnaire.name} {!!((object.questionnaire_results || []).filter(fff => questionnaire.id === fff.questionnaire_id)[0] || {}).completed_at && <VisibilityIcon onClick={() => {
                setActiveQResult(((object.questionnaire_results || []).filter(fff => questionnaire.id === fff.questionnaire_id)[0] || {}).id);
                setQResultOpen(true);
              }} sx={{cursor: 'pointer', marginBottom: -1}} color="primary" />}</Typography>
              {!!((object.questionnaire_results || []).filter(fff => questionnaire.id === fff.questionnaire_id)[0] || {}).invited_at && <Typography>{t("general.terms.invited_on")} <Dt d={((object.questionnaire_results || []).filter(fff => questionnaire.id === fff.questionnaire_id)[0] || {}).invited_at} f="dd/LL/y t" /></Typography>}
              {!!((object.questionnaire_results || []).filter(fff => questionnaire.id === fff.questionnaire_id)[0] || {}).completed_at && <Typography>{t("general.terms.completed_on")} <Dt d={((object.questionnaire_results || []).filter(fff => questionnaire.id === fff.questionnaire_id)[0] || {}).completed_at} f="dd/LL/y t" /></Typography>}
              {!((object.questionnaire_results || []).filter(fff => questionnaire.id === fff.questionnaire_id)[0] || {}).completed_at && <Button
                id={`invite${questionnaire.id}`}
                label={t("general.terms.invite")}
                onClick={() => {
                  setActiveQ(questionnaire);
                  setInvitation(true);
                }}
                size="small"
                sx={{marginTop: 1, paddingTop: 0.25, paddingBottom: 0.25}}
              />}
              <Divider />
            </Grid>)}
          </Grid>
        </Paper>}

      </Grid>




      {!object.merge_pending && <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Button
          label={t("coach.submenu.detail.merge_request", "Deze gebruiker is dubbel")}
          id='goto_merge'
          contained
          secondary
          onClick={(v) => {
            navigate(`/user/${object.id}/merge_request`);
          }}
          sx={{marginTop: 2}}
        />
      </Grid>}




      {!object.consent_status?.is_blocked && <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Button
          label={t("coach.submenu.detail.unlink_from_practice", "Deze gebruiker ontlinken")}
          id='unlink_user'
          text
          secondary
          destroyBtn
          startIcon={<LinkOffIcon />}
          onClick={(v) => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_unlink_from_practice',
                id: object?.id,
                practice_id: (login?.practice_id || currentPractice?.id)
              },
              unlink: true
            });
          }}
          sx={{marginTop: 2}}
        />
      </Grid>}
      {!!object.consent_status?.is_blocked && <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Button
          label={t("coach.submenu.detail.ununlink_from_practice", "Deze gebruiker terug linken")}
          id='link_user'
          text
          startIcon={<LinkIcon />}
          onClick={(v) => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_unlink_from_practice',
                id: object?.id,
                practice_id: (login?.practice_id || currentPractice?.id),
                reverse: 1
              },
              unlink: true
            });
          }}
          sx={{marginTop: 2}}
        />
      </Grid>}
    </Grid>}
    
    <SnackCopied open={copyOpen} setOpen={setCopyOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
    <Premium
      open={premiumEhealthOpen}
      setOpen={(b) => {
        setPremiumEhealthOpen(b);
      }}
      mode="ehealth"
    />
    <Premium
      open={premiumNutriAdviceOpen}
      setOpen={(b) => {
        setPremiumNutriAdviceOpen(b);
      }}
      mode="nutri_advice"
    />
    <Premium
      open={premiumNutriMailOpen}
      setOpen={(b) => {
        setPremiumNutriMailOpen(b);
      }}
      mode="nutri_mail"
    />
    <Premium
      open={premiumDiaryEatOpen}
      setOpen={(b) => {
        setPremiumDiaryEatOpen(b);
      }}
      mode="nutri_diary_eat"
    />
    <Premium
      open={premiumDiaryMoveOpen}
      setOpen={(b) => {
        setPremiumDiaryMoveOpen(b);
      }}
      mode="nutri_diary_move"
    />
    <Premium
      open={premiumNutriAppointmentOpen}
      setOpen={(b) => {
        setPremiumNutriAppointmentOpen(b);
      }}
      mode="nutri_appointment"
    />
    <Premium
      open={premiumMeasuresWeightOpen}
      setOpen={(b) => {
        setPremiumMeasuresWeightOpen(b);
      }}
      mode="measures_weight"
    />

    <Confirm
      open={invitation}
      setOpen={setInvitation}
      title={t("coach.questionnaires.send_invite_dialog.title")}
      content={t("coach.questionnaires.send_invite_dialog.content")}
      execute={() => {
        if (!!object && !!activeQ) {
          mutationSave.mutate({
            formData: {
              object: 'questionnaire_result',
              fields: ['id'],
              id: ((object.questionnaire_results || []).filter(fff => activeQ.id === fff.questionnaire_id)[0] || {}).id,
              ob: {
                id: ((object.questionnaire_results || []).filter(fff => activeQ.id === fff.questionnaire_id)[0] || {}).id,
                user_id: object.id,
                questionnaire_id: activeQ.id,
                practice_id: currentPractice?.id,
                do_invite: true
              }
            }
          });
        }
      }}
    />

    <Base
      name="group_eat"
      hidden={!groupsEatEditing}
      title={t("coach.dialogs.adjust_eat_group.title")}
      content={<Grid container spacing={1}>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("coach.models.clients.group_eat", "Eetdagboek groep")}
            displayEmpty
            id="group_eat"
            value={newEatGroup || ''}
            setValue={(v) => {
              setNewEatGroup(v.toString());
            }}
          >
            <MenuItem value="">{t("eat_diary.groups.none")}</MenuItem>
            <MenuItem value="full">{t("eat_diary.groups.full")}</MenuItem>
            <MenuItem value="mindfull">{t("eat_diary.groups.mindfull")}</MenuItem>
            <MenuItem value="complaints">{t("eat_diary.groups.complaints")}</MenuItem>
            <MenuItem value="move">{t("eat_diary.groups.move")}</MenuItem>
            <MenuItem value="directions">{t("eat_diary.groups.directions")}</MenuItem>
          </Select>
        </Grid>
        
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setGroupsEatEditing(false);}}>{t("coach.general.actions.cancel")}</Btn>
        <Btn onClick={(e) => {
          setGroupsEatEditing(false);
          mutationCustomAction.mutate({formData: {
            object: 'user',
            class_action: 'custom_api_set_group',
            id: object?.id,
            eat: newEatGroup,
            is_eat: 1
          }, group: true});
        }}>{t("coach.general.actions.save")}</Btn>
      </DialogActions>}
      open={groupsEatEditing}
      setOpen={setGroupsEatEditing}
    />

    <Base
      name="group_move"
      hidden={!groupsMoveEditing}
      title={t("coach.dialogs.adjust_move_group.title")}
      content={<Grid container spacing={1}>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("coach.models.clients.group_move", "Beweegdagboek groep")}
            displayEmpty
            id="group_move"
            value={newMoveGroup || ''}
            setValue={(v) => {
              setNewMoveGroup(v.toString());
            }}
          >
            <MenuItem value="">{t("move_diary.groups.none")}</MenuItem>
            <MenuItem value="full">{t("move_diary.groups.full")}</MenuItem>
            <MenuItem value="limited">{t("move_diary.groups.limited")}</MenuItem>
            <MenuItem value="complaints">{t("move_diary.groups.complaints")}</MenuItem>
          </Select>
        </Grid>
        
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setGroupsMoveEditing(false);}}>{t("coach.general.actions.cancel")}</Btn>
        <Btn onClick={(e) => {
          setGroupsMoveEditing(false);
          mutationCustomAction.mutate({formData: {
            object: 'user',
            class_action: 'custom_api_set_group',
            id: object?.id,
            move: newMoveGroup,
            is_move: 1
          }, group: true});
        }}>{t("coach.general.actions.save")}</Btn>
      </DialogActions>}
      open={groupsMoveEditing}
      setOpen={setGroupsMoveEditing}
    />

    <Base
      name="alert"
      hidden={!ehealthInitOpen}
      title={t("general.ehealth.not_inited_title")}
      content={<Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>{t("general.ehealth.not_inited_content")}</Typography>
        </Grid>
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setEhealthInitOpen(false);}}>{t("general.confirm.actions.cancel")}</Btn>
        <Btn onClick={(e) => {
          navigate("/settings", {state: {tab: 4}});
          setEhealthInitOpen(false);
        }}>{t("general.ehealth.not_inited_action_settings")}</Btn>
      </DialogActions>}
      open={ehealthInitOpen}
      setOpen={setEhealthInitOpen}
    />

    {!!questionnaireResult && <CoachShowQuestionnaire
      open={qResultOpen}
      setOpen={setQResultOpen}
      object={questionnaireResult}
      setSavedOpen={(b) => {
        setSavedOpen(b);
        objectRefetch();
        questionnaireResultRefetch();
      }}
    />}
  </Page>;
}
